<!--eslint-disable-->

<template>
  <div>
    <div class="cart-spec">
      <b-row class="mb-75">
        <b-col
          v-if="type === 'ORDER'"
          sm="12"
        >
          <b-alert
            variant="primary"
            show
          >
            <div class="alert-heading">
              <span><strong class="pr-25">{{ $t('OrderID') }}: </strong>
                <span v-if="offer.number">{{ offer.number }}</span>
                <feather-icon
                  v-else
                  icon="MinusIcon"
                />
              </span>
            </div>

            <div
              v-if="type === 'ORDER' && offer.contactThreadCartBox && offer.contactThreadCartBox.paymentType === 'LOYALTY_POINTS'"
              class="alert-body"
              v-html="$tc('OrderRealizedByPoints', 1, { points: (offer.loyaltyPointsPrice || 0) * -1 })"
            />
          </b-alert>
          <b-alert
            variant="primary"
            v-if="offer.note"
            show
          >
            <div class="alert-heading">
              <span><strong class="pr-25">{{ $t('Note') }}: </strong></span>
            </div>
              <div class="alert-body">
                  <span v-if="offer.note">{{ offer.note }}</span>
                  <feather-icon
                          v-else
                          icon="MinusIcon"
                  />
              </div>
          </b-alert>
        </b-col>
        <!-- Contact -->
        <b-col
          sm="12"
          md="6"
          class="d-flex flex-column flex-md-row"
        >
          <strong>{{ $t('Contact') }}</strong>

          <router-link
            class="ml-50"
            :class="{ 'deleted-text': thread.contact.deletedAt }"
            :to="`/contact/${thread.contact.id}`"
          >
            {{ (`${thread.contact.firstName} ${thread.contact.lastName}`) | truncate(24, '…') }}
          </router-link>
        </b-col>

        <!-- Thread -->
        <b-col
          sm="12"
          md="6"
          class="d-flex flex-column flex-md-row"
        >
          <strong>{{ $t('Thread') }}</strong>

          <router-link
            class="ml-50"
            :class="{ 'deleted-text': thread.contact.deletedAt }"
            :to="`/contact/${thread.contact.id}/thread/${thread.id}/offers`"
          >
            {{ thread.name | truncate(24) }}
          </router-link>
        </b-col>

        <b-col
          v-if="type !== 'OFFER' && offer"
          sm="12"
          class="mt-25"
        >

          <div class="border p-1 rounded cursor-pointer d-flex justify-content-between">
            <div>
              <feather-icon icon="MapPinIcon" />
              {{ formatAddress(offer) }}
            </div>
          </div>

        </b-col>
      </b-row>
      <hr>
      <b-row>
        <!-- Status -->
        <b-col
          sm="12"
          md="6"
          class="mt-25"
        >
          <div class="d-flex justify-content-between cart-item">
            <strong>{{ $t('Status') }}</strong>

            <b-badge
              v-if="offer.status === 'CANCELLED'"
              v-b-tooltip.hover.v-danger
              variant="light-danger"
              :title="$tc(`offer.status.${offer.status}`, type === 'OFFER' ? 1 : 2)"
            >
              {{ $tc(`offer.status.${offer.status}`, type === 'OFFER' ? 1 : 2) }}
            </b-badge>
            <b-badge
              v-else-if="offer.status === 'NEW'"
              v-b-tooltip.hover.v-primary
              variant="light-primary"
              :title="$tc(`offer.status.${offer.status}`, type === 'OFFER' ? 1 : 2)"
            >
              {{ $tc(`offer.status.${offer.status}`, type === 'OFFER' ? 1 : 2) }}
            </b-badge>
            <b-badge
              v-else-if="offer.status === 'ACCEPTED'"
              v-b-tooltip.hover.v-success
              variant="light-success"
              :title="$tc(`offer.status.${offer.status}`, type === 'OFFER' ? 1 : 2)"
            >
              {{ $tc(`offer.status.${offer.status}`, type === 'OFFER' ? 1 : 2) }}
            </b-badge>
            <b-badge
              v-else
              v-b-tooltip.hover.v-success
              variant="light-primary"
              :title="$tc(`offer.status.${offer.status}`, type === 'OFFER' ? 1 : 2)"
            >
              {{ $tc(`offer.status.${offer.status}`, type === 'OFFER' ? 1 : 2) }}
            </b-badge>
          </div>
        </b-col>

        <!-- SendEmail -->
        <b-col
          v-if="type === 'OFFER'"
          sm="12"
          md="6"
          class="mt-25"
        >
          <div class="d-flex justify-content-between cart-item">
            <strong>{{ $t('ShippedToCustomer') }}</strong>

            <div>
              <b-badge
                :variant="offer.contactThreadCartBox.sendEmail ? 'light-success' : 'light-danger'"
                pill
              >
                <feather-icon :icon="offer.contactThreadCartBox.sendEmail ? 'CheckIcon' : 'MinusIcon'" />
              </b-badge>
            </div>
          </div>
        </b-col>

        <!-- messageStatus -->
        <b-col
          v-if="type === 'OFFER'"
          sm="12"
          md="6"
          class="mt-25"
        >
          <div class="d-flex justify-content-between cart-item">
            <strong>{{ $t('offer.MessageStatus') }}</strong>

            <span class="d-flex justify-content-center">
              <b-badge
                :id="`mails_${offer.id}`"
                class="d-flex align-items-center"
                variant="light-primary"
                pill
              >
                <feather-icon
                  icon="CheckSquareIcon"
                  size="10"
                  class="mr-25"
                />
                {{ getDeliveredMessagesCount(offer.contactThreadCartOfferMails).length }} / {{ (offer.contactThreadCartOfferMails || []).length }}
              </b-badge>
              <b-popover
                v-if="(offer.contactThreadCartOfferMails || []).length"
                :target="`mails_${offer.id}`"
                triggers="hover"
              >
                <div
                  v-for="(contactThreadMail, mailIndex) in (offer.contactThreadCartOfferMails || [])"
                  :key="`mails_${offer.id}_${mailIndex}`"
                  class="text-center"
                >
                  <avatar
                    :to="{ name:'contact', params: { contactId: contactThreadMail.contact.id }}"
                    :user="contactThreadMail.contact"
                  />
                  <div><small><b-badge
                    class="w-100"
                    block
                    :variant="resolveEmailStatusColor(contactThreadMail.mail.status)"
                  >{{ $t(`${title(contactThreadMail.mail.status.toLowerCase())}`) }}</b-badge></small></div>
                  <hr>
                </div>
              </b-popover>
            </span>
          </div>
        </b-col>

        <b-col
          v-if="type === 'OFFER'"
          sm="12"
          md="6"
          class="mt-25"
        >
          <div class="d-flex justify-content-between cart-item">
            <strong>{{ $t('offer.ValidityOfferTerm') }}</strong>

            <div>
              <b-badge
                v-b-tooltip
                variant="primary"
                :title="`${$t('offer.ValidityOfferDate')}: ${formatDate(validityEndDate, { month: 'short', day: 'numeric', year: 'numeric' }, $i18n.locale)}`"
              >
                {{ offer.offerValidityDays }} {{ $tc('Days', offer.offerValidityDays) }}
              </b-badge>
            </div>
          </div>
        </b-col>

        <!-- WWW -->
        <b-col
          v-if="type === 'OFFER'"
          sm="12"
          md="6"
          class="mt-25"
        >
          <div class="d-flex justify-content-between cart-item">
            <strong>{{ $t('WWW') }}</strong>

            <div>
              <b-badge
                v-if="offer.offerWebTemplate"
                v-b-tooltip.hover
                variant="success"
                pill
                :title="offer.offerWebTemplate.name"
                :href="`${offer.offerWebUrlLoggedUser}`"
              >
                <feather-icon icon="ExternalLinkIcon" />
              </b-badge>
              <b-badge
                v-else
                variant="light-danger"
                pill
              >
                <feather-icon icon="MinusIcon" />
              </b-badge>
            </div>
          </div>
        </b-col>

        <!-- Gross -->
        <b-col
          sm="12"
          md="6"
          class="mt-25"
        >
          <div class="d-flex justify-content-between cart-item">
            <strong>{{ $t('GrossSum') }}</strong>

            <b-badge
              v-b-tooltip.hover.v-success
              variant="light-primary"
              :title="$t(`GrossSum`)"
            >
              {{ offer.priceGrossEnd || 0 | priceGrossFormat }} PLN
            </b-badge>
          </div>
        </b-col>

        <!-- Net -->
        <b-col
          sm="12"
          md="6"
          class="mt-25"
        >
          <div class="d-flex justify-content-between cart-item">
            <strong>{{ $t('NetSum') }}</strong>

            <b-badge
              v-b-tooltip.hover.v-success
              variant="light-primary"
              :title="$t(`NetSum`)"
            >
              {{ offer.price || 0 | priceFormat }} PLN
            </b-badge>
          </div>
        </b-col>

        <!-- Vat -->
        <b-col
          sm="12"
          md="6"
          class="mt-25"
        >
          <div class="d-flex justify-content-between cart-item">
            <strong>{{ $t('TotalVAT') }}</strong>

            <b-badge
              v-b-tooltip.hover.v-success
              variant="light-primary"
              :title="$t(`TotalVAT`)"
            >
              {{ (offer.priceGrossEnd - offer.price) || 0 | priceGrossFormat }} PLN
            </b-badge>
          </div>
        </b-col>
      </b-row>
      <!-- products -->
      <!-- slides -->
      <swiper
        v-if="swiperLoaded"
        class="swiper-paginations py-1"
        :options="swiperOptions"
      >
        <!-- slides -->
        <swiper-slide
          v-for="(data) in offer.contactThreadCartBox.contactThreadCartBoxProducts"
          :key="data.text"
          class="rounded"
        >
          <div class="w-100">
            <b-alert
              show
              variant="secondary"
            >
              <h5 class="alert-heading text-nowrap text-truncate">
                <span v-if="data.translations && data.translations.length">{{ data.translations[0].name }}</span>
              </h5>
              <div
                v-if="false"
                class="alert-body text-muted"
              >
                <span
                  v-if="data.translations && data.translations.length"
                  v-html="data.translations[0].description || '-'"
                />
              </div>
            </b-alert>

            <div class="d-flex justify-content-between mb-1">
              <strong>{{ $t('Offer.Amount') }}</strong>
              <b-badge variant="primary">
                {{ data.amount }}
              </b-badge>
            </div>

            <div class="d-flex justify-content-between mb-1">
              <strong>{{ $t('Unit') }}</strong>
              <b-badge variant="primary">
                {{ data.shopProduct && data.shopProduct.amountUnitType ? $t(`unit.${data.shopProduct.amountUnitType}`) : '-' }}
              </b-badge>
            </div>

            <div
              class="d-flex justify-content-between"
            >
              <strong>{{ $t('NetAmount') }}</strong>
              <span>{{ data.priceWithAllDiscount || 0 | priceFormat }} PLN</span>
            </div>

            <div
              class="d-flex justify-content-between"
            >
              <strong>{{ $t('Offer.TotalNetPrice') }}</strong>
              <span>{{ data.priceWithAllDiscount * data.amount || 0 | priceFormat }} PLN</span>
            </div>

            <div
              v-if="type === 'ORDER'"
              class="d-flex justify-content-between"
            >
              <strong>{{ $t('UserDiscount') }}</strong>
              <span>{{ data.sellerDiscount || 0 | priceFormat }} PLN</span>
            </div>
            <hr>

            <div
              class="d-flex justify-content-between"
            >
              <strong>{{ $t('Gross') }}</strong>
              <span>{{ data.priceGrossWithAllDiscount || 0 | priceGrossFormat }} PLN</span>
            </div>
            <!--           TOTAL -->
            <div
              class="d-flex justify-content-between"
            >
              <strong>{{ $t('Offer.TotalGrossPrice') }}</strong>
              <span>{{ data.priceGrossWithAllDiscount * data.amount || 0 | priceGrossFormat }} PLN</span>
            </div>
            <!-- Attributes -->
            <!--            <div>-->
            <!--              <app-collapse class="pl-0 ml-0">-->
            <!--                <app-collapse-item-->
            <!--                  :title="$t('AttributeList')"-->
            <!--                  class="shadow-none"-->
            <!--                >-->
            <!--                  <b-row>-->
            <!--                    <b-col-->
            <!--                      v-for="(attribute, index) in data.contactThreadCartBoxProductAttributes"-->
            <!--                      :key="`attribute_${productIndex}_${index}_${componentId}`"-->
            <!--                      sm="12"-->
            <!--                      md="6"-->
            <!--                      class="d-flex border-bottom justify-content-between w-50 py-25"-->
            <!--                    >-->
            <!--                      <div class="font-weight-bold text-truncate">-->
            <!--                        <span v-if="attribute.translations && attribute.translations.length">{{ attribute.translations[0].name }}</span>-->
            <!--                      </div>-->
            <!--                      <div v-if="attribute.valueContactThreadCartBoxProductAttributeOptions && attribute.valueContactThreadCartBoxProductAttributeOptions.length">-->
            <!--                        <b-badge-->
            <!--                          :id="`attribute_options_${productIndex}_${index}_${componentId}`"-->
            <!--                          variant="light-primary"-->
            <!--                        >-->
            <!--                          {{ $t('Selected') }}: {{ attribute.valueContactThreadCartBoxProductAttributeOptions.length }}-->
            <!--                        </b-badge>-->
            <!--                        <b-popover-->
            <!--                          :target="`attribute_options_${productIndex}_${index}_${componentId}`"-->
            <!--                          triggers="hover"-->
            <!--                        >-->
            <!--                          <div>-->
            <!--                            <b-badge-->
            <!--                              v-for="(option, optionIndex) in attribute.valueContactThreadCartBoxProductAttributeOptions"-->
            <!--                              :key="`attribute_options_${productIndex}_${optionIndex}_${index}`"-->
            <!--                              class="mr-25"-->
            <!--                            >-->
            <!--                              <span v-if="option.translations && option.translations.length">{{ option.translations[0].name }}</span>-->
            <!--                            </b-badge>-->
            <!--                          </div>-->
            <!--                        </b-popover>-->
            <!--                      </div>-->
            <!--                      <div v-else-if="attribute.value">-->
            <!--                        {{ attribute.value }}-->
            <!--                      </div>-->
            <!--                      <div v-else>-->
            <!--                        <feather-icon icon="MinusIcon" />-->
            <!--                      </div>-->
            <!--                    </b-col>-->
            <!--                  </b-row>-->
            <!--                </app-collapse-item>-->
            <!--              </app-collapse>-->
            <!--            </div>-->
          </div>
        </swiper-slide>

        <!-- Add Arrows -->
        <div
          slot="pagination"
          class="swiper-pagination"
        />
      </swiper>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from 'moment'
import { formatDate, title } from '@core/utils/filter'
import { BAlert, BPopover, VBTooltip } from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import resolveEmailStatusColor from '@/helpers/emailStatuses'
import { grossPrice, netPriceAfterCartDiscount, netPriceByAmount } from '@/helpers/cartProductPrices'
import { priceGrossFormat } from '@core/filters/parts/valueFormat'

export default {
  name: 'ProductSpecification',
  components: {
    Swiper, SwiperSlide, BAlert, BPopover,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    type: {
      default: 'OFFER',
      type: String,
    },
    offer: {
      type: Object,
      default: null,
    },
    showImages: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    componentId: '',
    swiperOptions: {
      spaceBetween: 30,
      sorter: {
        sortBy: 'createdAt',
        sortDesc: true,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    },
    swiperPictures: {
      spaceBetween: 10,
      slidesPerView: 3,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
    },
    swiperLoaded: false,
  }),
  computed: {
    thread() {
      return this.offer.contactThreadCartBox.contactThread
    },
    validityEndDate() {
      return moment(this.offer.createdAt.date).add(this.offer.contactThreadCartBox.offerValidityDays, 'days').format('')
    },
  },
  watch: {
    offer: {
      deep: true,
      handler(n) {
        if (n?.priceGrossEnd === undefined || n?.priceGrossEnd === null) {
          this.offer.priceGrossEnd = this.offer.contactThreadCartBox.contactThreadCartBoxProducts.reduce((a, b) => a + (b.priceGrossWithAllDiscount * b.amount), 0) || 0
          // eslint-disable-next-line
          this.offer.price = this.offer.contactThreadCartBox.contactThreadCartBoxProducts.reduce((a, b) => a + (b.priceWithAllDiscount * b.amount), 0) || 0
        }
      },
    },
  },
  created() {
    this.componentId = String(Math.floor(Math.random() * 10) + 1)
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.$set(this, 'swiperLoaded', true)
      })
    }, 100)

    if (this.offer?.priceGrossEnd === undefined || this.offer?.priceGrossEnd === null) {
      this.offer.priceGrossEnd = this.offer.contactThreadCartBox.contactThreadCartBoxProducts.reduce((a, b) => a + (b.priceGrossWithAllDiscount * b.amount), 0) || 0
      // eslint-disable-next-line
      this.offer.price = this.offer.contactThreadCartBox.contactThreadCartBoxProducts.reduce((a, b) => a + (b.priceWithAllDiscount * b.amount), 0) || 0
    }
  },
  methods: {
    priceGrossFormat,
    netPriceByAmount,
    netPriceAfterCartDiscount,
    grossPrice,
    formatDate,
    getDeliveredMessagesCount(contactThreadOfferMails) {
      return (contactThreadOfferMails || []).filter(contactThreadMail => contactThreadMail.mail.status === 'DELIVERED' || contactThreadMail.mail.status === 'OPEN') ?? []
    },
    title,
    resolveEmailStatusColor,
    getProductPictures(product) {
      return product?.contactThreadOfferProductPictures && Array.isArray(product?.contactThreadOfferProductPictures) ? product.contactThreadOfferProductPictures.map(picture => picture.file) : []
    },
    getLocation() {
      return window.location.port ? 'https://dev.saleswizardapp.com' : window.location.origin
    },
    formatAddress(cart) {
      return `
        ${cart?.deliveryAddressStreet || ''} ${cart?.deliveryAddressNumber || ''}, ${cart?.deliveryAddressPostalCode || ''} ${cart?.deliveryAddressCity || ''}
      `
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';

.shadow-none {
  //& .card-header, & .card-body {
  //  padding-left: 0 !important;
  //  padding-right: 0 !important;
  //}
}
</style>
<style scoped lang="scss">
  .cart-item {
    margin-bottom: 6px;
  }
</style>
